// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Olympus-primary: mat.define-palette(mat.$indigo-palette);
$Olympus-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Olympus-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Olympus-theme: mat.define-light-theme((color: (primary: $Olympus-primary,
        accent: $Olympus-accent,
        warn: $Olympus-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Olympus-theme);

// ----------------------------------------------------------------------------------------------
// NOTE Variables -------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------

@import 'variables.scss';

// ----------------------------------------------------------------------------------------------
// NOTE Bootstrap -------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------

// NOTE Complete bootstrap
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

// NOTE Bootstrap configuration
// @import '../../../node_modules/bootstrap/scss/functions';
// @import '../../../node_modules/bootstrap/scss/variables';
// @import '../../../node_modules/bootstrap/scss/mixins';

// // NOTE Merge maps
// $theme-colors: map-merge($theme-colors, $custom-colors);

// @import '../../../node_modules/bootstrap/scss/utilities';

// // NOTE Bootstrap layout & components
// @import '../../../node_modules/bootstrap/scss/root';
// @import '../../../node_modules/bootstrap/scss/reboot';
// @import '../../../node_modules/bootstrap/scss/type';
// // @import '../../../node_modules/bootstrap/scss/images';
// @import '../../../node_modules/bootstrap/scss/containers';
// @import '../../../node_modules/bootstrap/scss/grid';
// @import '../../../node_modules/bootstrap/scss/tables';
// @import '../../../node_modules/bootstrap/scss/forms';
// @import '../../../node_modules/bootstrap/scss/buttons';
// @import '../../../node_modules/bootstrap/scss/transitions';
// @import '../../../node_modules/bootstrap/scss/dropdown';
// // @import '../../../node_modules/bootstrap/scss/button-group';
// @import '../../../node_modules/bootstrap/scss/nav';
// @import '../../../node_modules/bootstrap/scss/navbar';
// @import '../../../node_modules/bootstrap/scss/card';
// // @import '../../../node_modules/bootstrap/scss/accordion';
// // @import '../../../node_modules/bootstrap/scss/breadcrumb';
// // @import '../../../node_modules/bootstrap/scss/pagination';
// @import '../../../node_modules/bootstrap/scss/badge';
// // @import '../../../node_modules/bootstrap/scss/alert';
// // @import '../../../node_modules/bootstrap/scss/progress';
// // @import '../../../node_modules/bootstrap/scss/list-group';
// // @import '../../../node_modules/bootstrap/scss/close';
// @import '../../../node_modules/bootstrap/scss/toasts';
// @import '../../../node_modules/bootstrap/scss/modal';
// @import '../../../node_modules/bootstrap/scss/tooltip';
// @import '../../../node_modules/bootstrap/scss/popover';
// // @import '../../../node_modules/bootstrap/scss/carousel';
// @import '../../../node_modules/bootstrap/scss/spinners';
// // @import '../../../node_modules/bootstrap/scss/offcanvas';

// // NOTE Boostrap helpers
// @import '../../../node_modules/bootstrap/scss/helpers';

// // NOTE Boostrap utilities
// @import '../../../node_modules/bootstrap/scss/utilities/api';

// ----------------------------------------------------------------------------------------------
// NOTE External components ---------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------

// $roboto-font-path: "../../../node_modules/roboto-fontface/fonts" !default;
// @import "../../../node_modules/roboto-fontface/css/roboto/sass/roboto-fontface";
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');

// ----------------------------------------------------------------------------------------------
// NOTE Project style ---------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------

@import 'project.scss';

// ----------------------------------------------------------------------------------------------
// NOTE Overwrite styles ------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------

// @import 'modules/_bootstrap.scss';

html,
body {
  height: 100%;
  line-height: normal;

}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}